export enum SECTION_TYPE {
	SECTION_APPROACH_STEPS = "sectionApproachSteps",
	SECTION_BIG_TEXT = "sectionBigText",
	SECTION_BLOG_POST_HEADER = "sectionBlogPostHeader",
	SECTION_BLOG_POST_PAGE = "sectionBlogPostPage",
	SECTION_CAREER_HEADER = "sectionCareerHeader",
	SECTION_CAREER_PAGE = "sectionCareerPage",
	// SECTION_COMPANY_ACHIEVEMENTS = "sectionCompanyAchievements",
	SECTION_CONTACT_US_BIG = "sectionContactUsBig",
	SECTION_CONTACT_US_CAREER = "sectionContactUsCareer",
	SECTION_CONTACT_US_MEDIUM = "sectionContactUsMedium",
	SECTION_CONTACT_US_SMALL = "sectionContactUsSmall",
	REUSABLE_SECTION_CONTACT_US_SMALL = "reusableSectionContactUsSmall",
	SECTION_EXTENDED_APPROACH_MODELS = "sectionExtendedApproachModels",
	SECTION_GENERAL_BLOG_HEADER = "sectionGeneralBlogHeader",
	SECTION_GENERAL_BLOG_PAGE = "sectionGeneralBlogPage",
	SECTION_GENERAL_CAREERS_HEADERS = "sectionGeneralCareersHeader",
	SECTION_GENERAL_CAREERS_NO_VACANCIES = "sectionGeneralCareersNoVacancies",
	SECTION_GENERAL_CAREERS_PAGE = "sectionGeneralCareersPage",
	SECTION_GENERAL_INFORMATION = "sectionGeneralInformation",
	SECTION_GENERAL_PROJECTS_HEADER = "sectionGeneralProjectsHeader",
	SECTION_GENERAL_PROJECTS_PAGE = "sectionGeneralProjectsPage",
	SECTION_GRID_FEATURE_CARDS = "sectionGridFeatureCards",
	SECTION_INDUSTRY_HEADER = "sectionIndustryHeader",
	SECTION_LANDING_ABOUT_US_HEADER = "sectionLandingAboutUsHeader",
	SECTION_LANDING_CONTACT_US_PAGE = "sectionLandingContactUsPage",
	SECTION_LANDING_ERROR_PAGE = "sectionLandingErrorPage",
	SECTION_LANDING_HOME_HEADER = "sectionLandingHomeHeader",
	SECTION_LANDING_HOME_INDUSTRIES = "sectionLandingHomeIndustries",
	SECTION_LANDING_HOME_SERVICES = "sectionLandingHomeServices",
	SECTION_LANDING_PRIVACY_POLICY_HEADER = "sectionLandingPrivacyPolicyHeader",
	SECTION_LANDING_PRIVACY_POLICY_PAGE = "sectionLandingPrivacyPolicyPage",
	SECTION_LANDING_THANK_YOU_PAGE = "sectionLandingThankYouPage",
	SECTION_PROJECT_HEADER = "sectionProjectHeader",
	SECTION_PROJECTS = "sectionProjects",
	SECTION_RELATED_BLOG_POSTS = "sectionRelatedBlogPosts",
	SECTION_SEO_TEXT = "sectionSEOText",
	SECTION_SEPARATED_INFO_COLUMNS = "sectionSeparatedInfoColumns",
	SECTION_SERVICE_HEADER = "sectionServiceHeader",
	SECTION_TALENT_POOL = "sectionTalentPool",
	// SECTION_TESTIMONIALS = "sectionTestimonials",
	SECTION_WITH_CONFIGURABLE_ROWS = "sectionWithConfigurableRows",
	SECTION_WITH_EXTENDED_ROWS = "sectionWithExtendedRows",
	SECTION_WITH_PRIMARY_AND_SECONDARY_COLUMNS = "sectionWithPrimaryAndSecondaryColumns",
	SECTION_WITH_TWO_COLUMNS_GRID = "sectionWithTwoColumnsGrid",
	SECTION_WITH_FIXED_IMAGE = "sectionWithFixedImage",
}

export enum ConfigurableContactLinkTypeEnum {
	EXTERNAL = "EXTERNAL",
	EMAIL = "EMAIL",
	PHONE_NUMBER = "PHONE_NUMBER",
}

export enum SnackbarVariant {
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}
